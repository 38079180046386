export function getPhpCommentForPageTemplate(templateName) {
	return `<?php
/**
 * Template Name: ${templateName} template
 */ ?>
`;
}

export function getFileProtectionPhpString() {
	return `<?php defined('ABSPATH') || exit; ?>\n`;
}

export function convertCommentToPHP(htmlString) {
	const regex = /\?php([\s\S]*?)\?/g;
	const convertedString = htmlString.replace(regex, (match, p1) => {
		return `<?php${p1}?>`;
	});

	return convertedString;
}

export function parsePhpArrayArgs($element, prefix) {
	const attributes = Array.from($element.get(0).attributes);
	const args = {};

	for (let i = 0; i < attributes.length; i++) {
		const attribute = attributes[i];
		if (attribute.name.startsWith(prefix)) {
			const key = attribute.name.replace(prefix + '-', '');
			const value = attribute.value;

			// Преобразуем значение в соответствующий тип
			if (value === 'true') {
				args[key] = true;
			} else if (value === 'false') {
				args[key] = false;
			} else if (!isNaN(value)) {
				args[key] = Number(value);
			} else if (value.startsWith('$')) {
				args[key] = value; // PHP переменные оставляем как есть
			} else {
				args[key] = `'${value}'`; // Строки оборачиваем в кавычки
			}
		}
	}

	return args;
}

export function parseWpQueryArgs($element) {
	return parsePhpArrayArgs($element, 'data-query-arg');
}

export function parseTemplatePartArgs($element) {
	return parsePhpArrayArgs($element, 'data-template-arg');
}

export function formatAcfContextValue(context) {
	return context.toString()[0] === '$' ? context : `'${context}'`;
}

export function isElementChildOf($element, $parent) {
	return $element.parents().index($parent) !== -1;
}
