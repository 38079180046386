import $ from 'jquery';
import { insertPHP } from '../../core';

export function processSiteFields() {
	const $elementsWithSiteName = $('[data-wp-site]');

	$elementsWithSiteName.each((_, el) => {
		const $el = $(el);
		const key = $el.data('wpSite');
		const php = `<?php echo esc_html( get_bloginfo( '${key}' ) ); ?>`;

		insertPHP($(el), php);

		$(el).removeAttr('data-wp-site');
	});
}
