import $ from 'jquery';
import { insertPHP } from '../../core';
import { convertCommentToPHP, parseTemplatePartArgs } from '../../utils';

export const CONDITIONAL_ATTRIBUTES = [
	'[data-php-if]',
	'[data-php-elseif]',
	'[data-php-else]',
];
export const LOOP_TYPES = {
	WP_LOOP: 'wp_loop',
	WP_QUERY: 'wp_query',
	WP_POST_TERMS: 'post_terms',
	WP_TERMS: 'wp_terms',
	ACF_REPEATER: 'acf_repeater',
	ACF_FLEXIBLE: 'acf_flexible',
	ACF_RELATION: 'acf_relation',
};

export function processEmbedElements() {
	const $embeds = $('[data-php="embed"]');

	function processNode(index, node) {
		if (node.nodeType === Node.COMMENT_NODE) {
			const phpCode = convertCommentToPHP(node.nodeValue.trim());
			if (phpCode !== node.nodeValue.trim()) {
				$(node).replaceWith(document.createTextNode(phpCode));
			}
		} else if (node.nodeType === Node.ELEMENT_NODE) {
			$(node).contents().each(processNode);
		}
	}

	$embeds.each((i, el) => {
		const $el = $(el);
		const output = $el.data('output') ?? 'replace';
		const clenup = $el.data('cleanup') ?? true;

		console.log($el.data('phpValue'));

		let php;

		if (
			$el.data('phpValue') !== undefined &&
			$el.data('phpValue').trim().length > 0
		) {
			php = $el.data('phpValue');
		} else {
			$el.contents().each(processNode);
			php = $el.text().trim();
		}

		if (clenup) {
			$el.empty();
		}

		insertPHP($el, php, { output });

		$el.removeAttr('data-php');
		$el.removeAttr('data-php-value');
		$el.removeAttr('data-output');
		$el.removeAttr('data-output-attribute');
		$el.removeAttr('data-cleanup');
	});
}

export function preprocessTemplateParts() {
	$('[data-template-part]').each(function () {
		const $el = $(this);
		const templateName = $el.attr('data-template-part');

		if (!templateName) {
			return;
		}

		const args = parseTemplatePartArgs($el);
		let phpCode = '';

		if (Object.keys(args).length > 0) {
			let argsString = 'array(\n';
			Object.entries(args).forEach(([key, value]) => {
				argsString += `    '${key}' => ${value},\n`;
			});
			argsString += ')';

			phpCode = `<?php get_template_part( '${templateName}', null, ${argsString} ); ?>`;
		} else {
			phpCode = `<?php get_template_part( '${templateName}' ); ?>`;
		}

		$el.attr('data-php', 'embed');
		$el.attr('data-php-value', phpCode);

		const attributes = Array.from($el.get(0).attributes);
		attributes.forEach((attr) => {
			if (attr.name.startsWith('data-template-')) {
				$el.removeAttr(attr.name);
			}
		});
	});
}

function getLoopContext($element) {
	if ($element.data('wp') === 'wp_loop') return LOOP_TYPES.WP_LOOP;
	if ($element.data('wp') === 'wp_query') return LOOP_TYPES.WP_QUERY;
	if ($element.data('wp') === 'post_terms') return LOOP_TYPES.WP_POST_TERMS;
	if ($element.data('wpTerms') !== undefined) return LOOP_TYPES.WP_TERMS;
	if ($element.data('acfRepeater')) return LOOP_TYPES.ACF_REPEATER;
	if ($element.data('acfFlexible')) return LOOP_TYPES.ACF_FLEXIBLE;
	if ($element.data('acfRelation')) return LOOP_TYPES.ACF_RELATION;
	return null;
}

// Базовая функция для получения условий
export function getPhpConditions($element) {
	if (!$element || !$element.length) return null;

	const ifCondition = $element.data('phpIf');
	const elseIfCondition = $element.data('phpElseif');
	const hasElse = $element.data('phpElse') !== undefined;

	// Очищаем атрибуты
	$element
		.removeAttr('data-php-if')
		.removeAttr('data-php-elseif')
		.removeAttr('data-php-else');

	// Если нет условий, возвращаем null
	if (!ifCondition && !elseIfCondition && !hasElse) return null;

	return {
		if: ifCondition ? `<?php if ( ${ifCondition} ) : ?>` : null,
		elseif: elseIfCondition ? `<?php elseif ( ${elseIfCondition} ): ?>` : null,
		else: hasElse ? '<?php else : ?>' : null,
	};
}

function getLoopCondition(loopType, conditionType) {
	const conditions = {
		first: {
			[LOOP_TYPES.WP_LOOP]: '$wp_query->current_post === 0',
			[LOOP_TYPES.WP_QUERY]: '$custom_query->current_post === 0',
			[LOOP_TYPES.WP_POST_TERMS]: '$post_term_index === 0',
			[LOOP_TYPES.WP_TERMS]: '$term_index === 0',
			[LOOP_TYPES.ACF_REPEATER]: 'get_row_index() === 1',
			[LOOP_TYPES.ACF_FLEXIBLE]: 'get_row_index() === 1',
			[LOOP_TYPES.ACF_RELATION]: '$related_post_index === 0',
		},
		last: {
			[LOOP_TYPES.WP_LOOP]:
				'$wp_query->current_post === $wp_query->post_count - 1',
			[LOOP_TYPES.WP_QUERY]:
				'$custom_query->current_post === $custom_query->post_count - 1',
			[LOOP_TYPES.WP_POST_TERMS]: '$post_term_index === count( $terms ) - 1',
			[LOOP_TYPES.WP_TERMS]: '$term_index === count( $terms ) - 1',
			[LOOP_TYPES.ACF_REPEATER]: 'get_row_index() === get_row_count()',
			[LOOP_TYPES.ACF_FLEXIBLE]: 'get_row_index() === get_row_count()',
			[LOOP_TYPES.ACF_RELATION]: '$related_post_index === count( $posts ) - 1',
		},
		even: {
			[LOOP_TYPES.WP_LOOP]: '$wp_query->current_post % 2 === 0',
			[LOOP_TYPES.WP_QUERY]: '$custom_query->current_post % 2 === 0',
			[LOOP_TYPES.WP_POST_TERMS]: '$post_term_index % 2 === 0',
			[LOOP_TYPES.WP_TERMS]: '$term_index % 2 === 0',
			[LOOP_TYPES.ACF_REPEATER]: 'get_row_index() % 2 === 0',
			[LOOP_TYPES.ACF_FLEXIBLE]: 'get_row_index() % 2 === 0',
			[LOOP_TYPES.ACF_RELATION]: '$related_post_index % 2 === 0',
		},
		odd: {
			[LOOP_TYPES.WP_LOOP]: '$wp_query->current_post % 2 !== 0',
			[LOOP_TYPES.WP_QUERY]: '$custom_query->current_post % 2 !== 0',
			[LOOP_TYPES.WP_POST_TERMS]: '$post_term_index % 2 !== 0',
			[LOOP_TYPES.WP_TERMS]: '$term_index % 2 !== 0',
			[LOOP_TYPES.ACF_REPEATER]: 'get_row_index() % 2 !== 0',
			[LOOP_TYPES.ACF_FLEXIBLE]: 'get_row_index() % 2 !== 0',
			[LOOP_TYPES.ACF_RELATION]: '$related_post_index % 2 !== 0',
		},
	};

	return conditions[conditionType][loopType] || null;
}

export function convertLoopConditions($elements) {
	$elements.each((_, el) => {
		const $el = $(el);
		const context = getLoopContext(
			$el.closest(
				'[data-wp="wp_loop"], [data-wp="wp_query"], [data-wp="post_terms"], [data-wp-terms], [data-acf-repeater], [data-acf-flexible], [data-acf-relation]'
			)
		);

		if (!context) return;

		// Проверка на первый элемент
		if ($el.data('ifFirst') !== undefined) {
			const condition = getLoopCondition(context, 'first');
			if (condition) {
				$el.attr('data-php-if', condition);
				$el.removeAttr('data-if-first');
			}
		}

		// Проверка на последний элемент
		if ($el.data('ifLast') !== undefined) {
			const condition = getLoopCondition(context, 'last');
			if (condition) {
				$el.attr('data-php-if', condition);
				$el.removeAttr('data-if-last');
			}
		}

		// Проверка на четный/нечетный
		if ($el.data('ifEven') !== undefined) {
			const condition = getLoopCondition(context, 'even');
			if (condition) {
				$el.attr('data-php-if', condition);
				$el.removeAttr('data-if-even');
			}
		}

		if ($el.data('ifOdd') !== undefined) {
			const condition = getLoopCondition(context, 'odd');
			if (condition) {
				$el.attr('data-php-if', condition);
				$el.removeAttr('data-if-odd');
			}
		}

		if ($el.data('elseifFirst') !== undefined) {
			const condition = getLoopCondition(context, 'first');
			if (condition) {
				$el.attr('data-php-elseif', condition);
				$el.removeAttr('data-elseif-first');
			}
		}

		if ($el.data('elseifLast') !== undefined) {
			const condition = getLoopCondition(context, 'last');
			if (condition) {
				$el.attr('data-php-elseif', condition);
				$el.removeAttr('data-elseif-last');
			}
		}

		if ($el.data('elseifEven') !== undefined) {
			const condition = getLoopCondition(context, 'even');
			if (condition) {
				$el.attr('data-php-elseif', condition);
				$el.removeAttr('data-elseif-even');
			}
		}

		if ($el.data('elseifOdd') !== undefined) {
			const condition = getLoopCondition(context, 'odd');
			if (condition) {
				$el.attr('data-php-elseif', condition);
				$el.removeAttr('data-elseif-odd');
			}
		}
	});

	return $elements;
}

// Функция для обработки группы условных элементов
export function processPhpConditionGroup($elements) {
	if (!$elements.length) return;

	let currentGroup = [];
	let isInGroup = false;

	$elements.each((_, el) => {
		const $el = $(el);
		const conditions = getPhpConditions($el);

		if (conditions) {
			if (conditions.if) {
				// Если начинается новая группа условий
				if (isInGroup) {
					// Закрываем предыдущую группу
					insertPHP(
						$(currentGroup[currentGroup.length - 1]),
						'<?php endif; ?>',
						{
							output: 'after',
							outputPattern: null,
						}
					);
				}
				currentGroup = [$el];
				isInGroup = true;
				insertPHP($el, conditions.if, {
					output: 'before',
					outputPattern: null,
				});
			} else if (conditions.elseif || conditions.else) {
				// Добавляем к текущей группе
				if (isInGroup) {
					currentGroup.push($el);
					insertPHP($el, conditions.elseif || conditions.else, {
						output: 'before',
						outputPattern: null,
					});
				}
			}
		}
	});

	// Закрываем последнюю группу
	if (isInGroup && currentGroup.length) {
		insertPHP($(currentGroup[currentGroup.length - 1]), '<?php endif; ?>', {
			output: 'after',
			outputPattern: null,
		});
	}
}

export function processGlobalConditions() {
	// Находим все элементы с условиями на странице
	const $conditionalElements = $(
		'[data-php-if], [data-php-elseif], [data-php-else]'
	).filter(
		(_, el) =>
			!$(el).closest(
				'[data-wp="wp_loop"], [data-wp="wp_query"], [data-wp="post_terms"], [data-wp-terms], [data-acf-repeater], [data-acf-group], [data-acf-flexible], [data-acf-relation]'
			).length
	);

	// Обрабатываем группы условий
	processPhpConditionGroup($conditionalElements);
}
