import $ from 'jquery';
import { preparePhpTemplate } from '../core';

export function createConvertHtmlToPhpButton() {
	const $button = $('<button></button>', {
		type: 'button',
		class: 'download-button',
		text: 'Convert to PHP',
	});

	$('body').append($button);

	return $button;
}

export function createDownloadButton() {
	const fileName = window.location.pathname.split('/').pop() || 'index';
	const phpFile = preparePhpTemplate(fileName);
	const fileUrl = URL.createObjectURL(phpFile);

	const $button = $('<a></a>', {
		class: 'download-button',
		text: 'Download ' + fileName + '.php',
		href: fileUrl,
		download: fileName + '.php',
	});

	$button.on('click', () => {
		setTimeout(() => URL.revokeObjectURL(fileUrl), 1_000);
	});

	$('body').append($button);

	return $button;
}

export function createCopyPhpToClipboardButton() {
	const $button = $('<button></button>', {
		type: 'button',
		class: 'copy-button',
		text: 'Copy PHP to clipboard',
	});

	const buttonText = $button.text();

	$button.on('click', async () => {
		const phpFile = preparePhpTemplate();
		navigator.clipboard
			.writeText(await phpFile.text())
			.then(() => $button.text('Copied!'))
			.catch(() => $button.text('Failed to copy!'))
			.finally(() => setTimeout(() => $button.text(buttonText), 1_000));
	});

	$('body').append($button);

	return $button;
}
