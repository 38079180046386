import $ from "jquery";
import { insertPHP } from "../../core";
import { parseWpQueryArgs } from "../../utils";
import {
	CONDITIONAL_ATTRIBUTES,
	convertLoopConditions,
	processPhpConditionGroup,
} from "../php/php";

export function processPostTitle($element) {
  if (!$element) {
    return;
  }

  if ($element.data("ifExists") !== undefined) {
    $element.attr("data-php-if", "get_the_title()");
    $element.removeAttr("data-if-exists");
  }

  const $conditionalElements = $element
    .find("[data-php-if], [data-php-elseif], [data-php-else]")
    .addBack("[data-php-if], [data-php-elseif], [data-php-else]");
  processPhpConditionGroup($conditionalElements);

  const php = `<?php the_title(); ?>`;

  insertPHP($element, php);

  $element.removeAttr("data-wp");
}

export function processPostTitles() {
  const $elementsWithPostTitle = $('[data-wp="post_title"]');

  $elementsWithPostTitle.each((_, el) => {
    processPostTitle($(el));
  });
}

export function processPostContent($element) {
  if (!$element) {
    return;
  }

  const php = `<?php the_content(); ?>`;

  insertPHP($element, php);

  $element.removeAttr("data-wp");
}

export function processPostContentFields() {
  const $elementsWithPostContent = $('[data-wp="post_content"]');

  $elementsWithPostContent.each((_, el) => {
    processPostContent($(el));
  });
}

export function processPostExcerpt($element) {
  if (!$element) {
    return;
  }

  const php = `<?php the_excerpt(); ?>`;

  insertPHP($element, php);

  $element.removeAttr("data-wp");
}

export function processPostExcerpts() {
  const $elementsWithPostExcerpt = $('[data-wp="post_excerpt"]');

  $elementsWithPostExcerpt.each((_, el) => {
    processPostExcerpt($(el));
  });
}

export function processPostLink($element) {
  if (!$element) {
    return;
  }

  $element.attr("data-output", "attribute");
  $element.attr("data-output-attribute", "href");

  const php = `<?php the_permalink(); ?>`;

  insertPHP($element, php);

  $element.removeAttr("data-wp");
  $element.removeAttr("data-output");
  $element.removeAttr("data-output-attribute");
}

export function processPostLinks() {
  const $elementsWithPostLink = $('[data-wp="post_link"]');

  $elementsWithPostLink.each((_, el) => {
    processPostLink($(el));
  });
}

export function processPostImage($element) {
  if (!$element) {
    return;
  }

  if ($element.data("ifExists") !== undefined) {
    $element.attr("data-php-if", "has_post_thumbnail()");
    $element.removeAttr("data-if-exists");
  }

  const $conditionalElements = $element
    .find("[data-php-if], [data-php-elseif], [data-php-else]")
    .addBack("[data-php-if], [data-php-elseif], [data-php-else]");
  processPhpConditionGroup($conditionalElements);

  $element.attr("data-output", "attribute");
  $element.attr("data-output-attribute", "src");

  const php = `<?php the_post_thumbnail_url(); ?>`;

  insertPHP($element, php);

  $element.removeAttr("data-wp");
  $element.removeAttr("data-output");
  $element.removeAttr("data-output-attribute");

  $element.removeAttr("srcset");
  $element.removeAttr("sizes");
}

export function processPostImages() {
  const $elementsWithPostImage = $('[data-wp="post_image"]');

  $elementsWithPostImage.each((_, el) => {
    processPostImage($(el));
  });
}

export function processPostDate($element) {
  const format = $element.data("date-format");
  const php = `<?php echo get_the_date("${format}"); ?>`;

  insertPHP($element, php);

  $element.removeAttr("data-wp");
  $element.removeAttr("data-date-format");
}

export function processPostDateFields() {
  const $elementsWithPostDate = $('[data-wp="post_date"]');

  $elementsWithPostDate.each((_, el) => {
    processPostDate($(el));
  });
}

export function processWpLoop($element) {
  if (!$element) {
    return;
  }

  const incrementAttribute = $element.data("increment");
  const incrementPrefix = $element.data("incrementPrefix") || "";
  const incrementValue = $element.data("incrementValue");
  const incrementAction = $element.data("incrementAction");

  const phpBefore = `<?php
		global $wp_query;
		if ( have_posts() ) : ?>`;
  const phpPrepend = `<?php while( have_posts() ) : the_post(); ?>`;
  const phpAppend = `<?php endwhile; ?>`;
  const phpAfter = `<?php endif; ?>`;

  const $simpleLoopElements = $element.find(
    "[data-if-first], [data-if-last], [data-if-even], [data-if-odd], [data-elseif-first], [data-elseif-last], [data-elseif-even], [data-elseif-odd]",
  );

  convertLoopConditions($simpleLoopElements);

  const hasChildrenWithConditions =
    $element
      .children()
      .filter((_, child) => $(child).is(CONDITIONAL_ATTRIBUTES.join(", ")))
      .length > 0;

  if (!hasChildrenWithConditions) {
    $element
      .children()
      .filter((index) => index > 0)
      .remove();
  } else {
    $element
      .children()
      .filter((_, child) => !$(child).is(CONDITIONAL_ATTRIBUTES.join(", ")))
      .remove();
    processPhpConditionGroup($element.children());
  }

  $element.children().each((_, child) => {
    const $child = $(child);

    const anotherConditionalElements = $child.find(
      CONDITIONAL_ATTRIBUTES.join(", "),
    );

    if (anotherConditionalElements.length > 0) {
      processPhpConditionGroup(anotherConditionalElements);
    }

    if (incrementAttribute && incrementValue) {
      let incrementResultValue = incrementValue.trim();

      switch (incrementAction) {
        case "prepend":
          incrementResultValue = `${incrementPrefix}${incrementResultValue}`;
          break;
        case "append":
          incrementResultValue = `${incrementResultValue}${incrementPrefix}`;
          break;
        case "replace":
        default:
          incrementResultValue = `${incrementPrefix}${incrementResultValue}`;
          break;
      }

      $child.attr(incrementAttribute, incrementResultValue);
    }
  });

  insertPHP($element, phpBefore, { output: "before", outputPattern: null });
  insertPHP($element, phpPrepend, { output: "prepend", outputPattern: null });
  insertPHP($element, phpAppend, { output: "append", outputPattern: null });
  insertPHP($element, phpAfter, { output: "after", outputPattern: null });
}

export function processWpLoops() {
  const $elementsWithWpLoop = $('[data-wp="wp_loop"]');

  $elementsWithWpLoop.each((_, el) => {
    processWpLoop($(el));
  });
}

export function processWpQuery($element) {
  if (!$element) {
    return;
  }

  const queryArgs = parseWpQueryArgs($element);
  const $targetElement = $element.data("targetElement")
    ? $element.find($element.data("targetElement"))
    : $element;

  let beforePHP = `<?php $query_args = array( `;
  Object.entries(queryArgs).forEach(([key, value]) => {
    beforePHP += `'${key}' => ${
      typeof value === "number" || value[0] === "$" ? value : `${value}`
    },\n`;
  });
  beforePHP += ` );\n`;
  beforePHP += `$custom_query = new WP_Query( $query_args );\n
	if ( $custom_query->have_posts() ): ?>`;

  insertPHP($element, beforePHP, { output: "before", outputPattern: null });
  insertPHP(
    $targetElement,
    `<?php while ( $custom_query->have_posts() ) : $custom_query->the_post(); ?>`,
    {
      output: "prepend",
      outputPattern: null,
    },
  );

  const $simpleLoopElements = $element.find(
    "[data-if-first], [data-if-last], [data-if-even], [data-if-odd], [data-elseif-first], [data-elseif-last], [data-elseif-even], [data-elseif-odd]",
  );

  convertLoopConditions($simpleLoopElements);

  const hasChildrenWithConditions =
    $element
      .children()
      .filter((_, child) => $(child).is(CONDITIONAL_ATTRIBUTES.join(", ")))
      .length > 0;

  if (!hasChildrenWithConditions) {
    $element
      .children()
      .filter((index) => index > 0)
      .remove();
  } else {
    $element
      .children()
      .filter((_, child) => !$(child).is(CONDITIONAL_ATTRIBUTES.join(", ")))
      .remove();
    processPhpConditionGroup($element.children());
  }

  $element.children().each((_, child) => {
    const $child = $(child);

    const anotherConditionalElements = $child.find(
      CONDITIONAL_ATTRIBUTES.join(", "),
    );

    if (anotherConditionalElements.length > 0) {
      processPhpConditionGroup(anotherConditionalElements);
    }
  });

  insertPHP($targetElement, `<?php endwhile; wp_reset_postdata(); ?>`, {
    output: "append",
    outputPattern: null,
  });
  insertPHP($element, `<?php endif; ?>\n`, {
    output: "after",
    outputPattern: null,
  });

  $element.removeAttr("data-wp");
}

export function processWpQueries() {
  const $elementsWithWpQuery = $('[data-wp="wp_query"]');

  $elementsWithWpQuery.each((_, el) => {
    processWpQuery($(el));
  });
}

export function processNextPostElements() {
  const $elementsWithNextPost = $('[data-wp="next_post"]');

  $elementsWithNextPost.each((_, el) => {
    const key = $(el).data("wp");
    const $el = $(el);
    const beforePHP = `<?php global $post; \n$current_post_id = $post->ID; \n$${key} = get_next_post(); \nif ( ! empty( $${key} ) ) : $post = $${key}; \nsetup_postdata( $post ); ?>\n`;
    const afterPHP = `<?php wp_reset_postdata(); endif; \n$post = get_post( $current_post_id ); \nsetup_postdata( $post ); ?>\n`;

    insertPHP($el, beforePHP, { output: "before", outputPattern: null });
    insertPHP($el, afterPHP, { output: "after", outputPattern: null });

    $el.removeAttr("data-wp");
  });
}

export function processPreviousPostElements() {
  const $elementsWithNextPost = $(
    '[data-wp="prev_post"], [data-wp="previous_post"]',
  );

  $elementsWithNextPost.each((_, el) => {
    const key = $(el).data("wp");
    const $el = $(el);
    const beforePHP = `<?php global $post; \n$current_post_id = $post->ID; \n$${key} = get_previous_post(); \nif ( ! empty( $${key} ) ) : \n $post = $${key}; \nsetup_postdata( $post ); ?>\n`;
    const afterPHP = `<?php wp_reset_postdata(); endif; \n$post = get_post( $current_post_id ); \nsetup_postdata( $post ); ?>\n`;

    insertPHP($el, beforePHP, { output: "before", outputPattern: null });
    insertPHP($el, afterPHP, { output: "after", outputPattern: null });

    $el.removeAttr("data-wp");
  });
}
