import * as $ from 'jquery';
import { insertPHP } from '../../core';
import {
	CONDITIONAL_ATTRIBUTES,
	convertLoopConditions,
	processPhpConditionGroup,
} from '../php/php';

export function setTaxonomyLink($element, taxonomy) {
	if (!$element) {
		return;
	}

	const tax = taxonomy ? taxonomy : $element.data('wpTaxonomy');

	$element.attr('href', `<?php echo esc_url( get_term_link( ${tax} ) ); ?>`);
}

export function setTaxonomyName($element, taxonomy) {
	if (!$element) {
		return;
	}

	const tax = taxonomy ? taxonomy : $element.data('wpTaxonomy');

	$element.text(`<?php echo esc_html( ${tax}->name ); ?>`);
}

export function processTaxonomyElement($element) {
	if (!$element) {
		return;
	}

	const taxonomy = $element.data('wpTaxonomy');
	const $dataWpTermElements = $element
		.find(`[data-wp-term]`)
		.addBack(`[data-wp-term]`);

	$dataWpTermElements.each((i, el) => {
		const $el = $(el);
		const wpTermTags = $el
			.data('wpTerm')
			.split(',')
			.map((tag) => tag.trim());

		wpTermTags.forEach((tag) => {
			switch (tag) {
				case 'name':
					setTaxonomyName($el, taxonomy);
					break;
				case 'link':
					setTaxonomyLink($el, taxonomy);
					break;
			}
		});

		$el.removeAttr('data-wp-term');
		$el.find('[data-acf]').attr('data-acf-context', taxonomy);
	});

	$element.removeAttr('data-wp-taxonomy');
}

export function processTaxonomyElements() {
	const $taxonomyElements = $('[data-wp-taxonomy]');

	$taxonomyElements.each((i, el) => {
		processTaxonomyElement($(el));
	});
}

export function processTermLoops() {
	const $termLoops = $('[data-wp-terms]');

	$termLoops.each((i, el) => {
		const $el = $(el);
		const taxonomyName = $el.data('wpTerms');
		const beforeContainerPHP = `<?php $terms = get_terms( array( 'taxonomy' => '${taxonomyName}' ) ); 
		if ( ! empty( $terms ) && ! is_wp_error( $terms ) ) : ?>\n`;

		insertPHP($el, beforeContainerPHP, { output: 'before' });
		insertPHP(
			$el,
			`<?php foreach ( $terms as $term_index => $${taxonomyName}_term ) : ?>\n`,
			{
				output: 'prepend',
			}
		);

		const $simpleLoopElements = $el.find(
			'[data-if-first], [data-if-last], [data-if-even], [data-if-odd], [data-elseif-first], [data-elseif-last], [data-elseif-even], [data-elseif-odd]'
		);
		convertLoopConditions($simpleLoopElements);

		const hasChildrenWithConditions =
			$el
				.children()
				.filter((_, child) => $(child).is(CONDITIONAL_ATTRIBUTES.join(', ')))
				.length > 0;

		if (!hasChildrenWithConditions) {
			$el
				.children()
				.filter((index) => index > 0)
				.remove();
		} else {
			$el
				.children()
				.filter((_, child) => !$(child).is(CONDITIONAL_ATTRIBUTES.join(', ')))
				.remove();
			processPhpConditionGroup($el.children());
		}

		insertPHP($el, `<?php endforeach; ?>\n`, { output: 'append' });
		insertPHP($el, `<?php endif; ?>\n`, { output: 'after' });

		$el.children().each((i, child) => {
			$(child).attr('data-wp-taxonomy', `$${taxonomyName}_term`);
		});

		$el.removeAttr('data-wp-terms');
	});
}

export function processPostTermsLoops() {
	const $postTermsLoopsElements = $('[data-wp="post_terms"]');

	$postTermsLoopsElements.each((i, el) => {
		const $el = $(el);
		const taxonomyName = $el.data('taxonomy');
		const beforeContainerPHP = `<?php $post_terms = get_the_terms( get_the_ID(), '${taxonomyName}' );
		if ( ! empty( $post_terms ) ) : ?>\n`;

		insertPHP($el, beforeContainerPHP, { output: 'before' });
		insertPHP(
			$el,
			`<?php foreach ( $post_terms as $post_term_index => $${taxonomyName}_term) : ?>`,
			{
				output: 'prepend',
			}
		);

		const $simpleLoopElements = $el.find(
			'[data-if-first], [data-if-last], [data-if-even], [data-if-odd], [data-elseif-first], [data-elseif-last], [data-elseif-even], [data-elseif-odd]'
		);
		
		convertLoopConditions($simpleLoopElements);

		const hasChildrenWithConditions =
			$el
				.children()
				.filter((_, child) => $(child).is(CONDITIONAL_ATTRIBUTES.join(', ')))
				.length > 0;

		if (!hasChildrenWithConditions) {
			$el
				.children()
				.filter((index) => index > 0)
				.remove();
		} else {
			$el
				.children()
				.filter((_, child) => !$(child).is(CONDITIONAL_ATTRIBUTES.join(', ')))
				.remove();
			processPhpConditionGroup($el.children());
		}

		$el.children().each((i, child) => {
			$(child).attr('data-wp-taxonomy', `$${taxonomyName}_term`);
		});

		insertPHP($el, `<?php endforeach; ?>\n`, { output: 'append' });
		insertPHP($el, `<?php endif; ?>\n`, { output: 'after' });

		$el.removeAttr('data-wp');
		$el.removeAttr('data-taxonomy');
	});
}

export function processTaxonomyPageTitle($element) {
	if (!$element) {
		return;
	}

	insertPHP($element, `<?php echo single_term_title(); ?>`);
}
