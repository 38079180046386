import $ from "jquery";
import { insertPHP } from "../../core";
import { formatAcfContextValue, isElementChildOf } from "../../utils";
import { processPhpConditionGroup } from "../php";
import { CONDITIONAL_ATTRIBUTES, convertLoopConditions } from "../php/php";

const ACF_SELECTORS = [
  "[data-acf]",
  "[data-acf-link]",
  "[data-acf-lightbox-video]",
  "[data-acf-relation]",
];

export function processAcfField($element) {
  if (!$element) {
    return;
  }

  const fieldKey = $element.data("acf");
  const context = $element.data("acfContext")
    ? formatAcfContextValue($element.data("acfContext"))
    : "false";
  const isChild = $element.data("acfChild") || false;
  const $targetElement = $element.data("targetElement")
    ? $element.find($element.data("targetElement"))
    : $element;
  const output = $element.data("output") ?? "html";
  const outputAttribute = $element.data("outputAttribute") ?? null;
  const outputPattern = $element.data("outputPattern") ?? null;

  if ($element.data("condition") || $element.data("ifExists") !== undefined) {
    const condition = !isChild
      ? `! empty( get_field( '${fieldKey}', ${context} ) )`
      : `! empty( get_sub_field( '${fieldKey}' ) )`;

    $element.attr("data-php-if", condition);
    $element.removeAttr("data-condition");
    $element.removeAttr("data-if-exists");
  }

  const $conditionalElements = $element
    .find("[data-php-if], [data-php-elseif], [data-php-else]")
    .addBack("[data-php-if], [data-php-elseif], [data-php-else]");

  processPhpConditionGroup($conditionalElements);

  const php = !isChild
    ? `<?php echo get_field( '${fieldKey}', ${context} ); ?>`
    : `<?php echo get_sub_field( '${fieldKey}' ); ?>`;

  insertPHP($targetElement, php, { output, outputAttribute, outputPattern });

  $element.removeAttr("data-acf");
  $element.removeAttr("data-acf-child");
  $element.removeAttr("data-acf-context");
  $element.removeAttr("data-output");
  $element.removeAttr("data-output-pattern");
  $element.removeAttr("data-output-attribute");
  $element.removeAttr("data-target-element");
}

export function processAcfFields() {
  const $elementsWithAcfField = $("[data-acf]");

  $elementsWithAcfField.each((_, el) => {
    processAcfField($(el));
  });
}

export function processAcfImageField($element) {
  if (!$element) {
    return;
  }

  const fieldKey = $element.data("acfImage");

  $element.attr("data-acf", fieldKey);
  $element.attr("data-output", "attribute");
  $element.attr("data-output-attribute", "src");

  $element.removeAttr("data-acf-image");
  $element.removeAttr("srcset");
  $element.removeAttr("sizes");
}

export function processAcfImageFields() {
  const $imageElements = $("[data-acf-image]");

  $imageElements.each((_, el) => {
    processAcfImageField($(el));
  });
}

export function processAcfBgImageField($element) {
  if (!$element) {
    return;
  }

  const fieldKey = $element.data("acfBgImage");

  $element.attr("data-acf", fieldKey);
  $element.attr("data-output", "style");
  $element.attr("data-output-pattern", 'background-image: url("%s");');

  $element.removeAttr("data-acf-bg-image");
}

export function processAcfBgImageFields() {
  const $bgImageElements = $("[data-acf-bg-image]");

  $bgImageElements.each((_, el) => {
    processAcfBgImageField($(el));
  });
}

export function processAcfLinkFields() {
  const $linkElements = $("[data-acf-link]");

  $linkElements.each((_, el) => {
    const $el = $(el);
    const fieldKey = $el.data("acfLink");
    const isChild = $el.data("acfChild") || false;
    const context = $el.data("acfContext")
      ? formatAcfContextValue($el.data("acfContext"))
      : "false";
    let beforePHP = isChild
      ? `<?php $link = get_sub_field( '${fieldKey}' );\n`
      : `<?php $link = get_field( '${fieldKey}', ${context} );\n`;

    beforePHP += `if ( ! empty( $link ) ) :
			$url = $link['url'];
			$title = $link['title'];
			$target = $link['target'] ? $link['target'] : '_self'; 
		?>\n`;
    const $targetElement = $el.data("targetElement")
      ? $el.find($el.data("targetElement"))
      : $el;
    const $linkElement = $el.find("a").addBack("a");

    insertPHP($el, beforePHP, { output: "before", outputPattern: null });
    insertPHP($linkElement, "<?php echo esc_url( $url ); ?>", {
      output: "attribute",
      outputAttribute: "href",
      outputPattern: null,
    });
    insertPHP($linkElement, "<?php echo esc_attr( $target ); ?>", {
      output: "attribute",
      outputAttribute: "target",
      outputPattern: null,
    });
    insertPHP($targetElement, "<?php echo esc_html( $title ); ?>", {
      outputPattern: null,
    });
    insertPHP($el, `<?php endif; ?>\n`, {
      output: "after",
      outputPattern: null,
    });

    $el.removeAttr("data-acf-link");
    $el.removeAttr("data-acf-child");
    $el.removeAttr("data-acf-context");
    $el.removeAttr("data-condition");
    $el.removeAttr("data-target-element");
    $el.removeAttr("data-target-element");
  });
}

export function processAcfGroupFields() {
  const $groupElements = $("[data-acf-group]");

  $groupElements.each((_, el) => {
    const $el = $(el);
    const fieldKey = $el.data("acfGroup");

    $el.attr("data-acf-repeater", fieldKey);
    $el.attr("data-acf-as-group", "true");

    $el.removeAttr("data-acf-group");
  });
}

export function processAcfFlexibleLayoutField($element) {
  if (!$element) {
    return;
  }

  const fieldKey = $element.data("acfFlexible");
  const context = $element.data("acfContext")
    ? formatAcfContextValue($element.data("acfContext"))
    : "false";
  const incrementAttribute = $element.data("increment");
  const incrementPrefix = $element.data("incrementPrefix");
  const $children = $element.children();

  insertPHP(
    $element,
    `<?php if ( have_rows( '${fieldKey}', ${context} ) ) : ?>\n`,
    {
      output: "before",
      outputPattern: null,
    },
  );
  insertPHP(
    $element,
    `\t<?php while ( have_rows( '${fieldKey}', ${context} ) ) : the_row(); ?>\n`,
    { output: "prepend", outputPattern: null },
  );

  $children.filter((_, child) => !$(child).is("[data-acf-layout]")).remove();

  $children.each((_, child) => {
    const $child = $(child);
    const layoutId = $child.data("acfLayout");
    const $simpleLoopElements = $element.find(
      "[data-if-first], [data-if-last], [data-if-even], [data-if-odd], [data-elseif-first], [data-elseif-last], [data-elseif-even], [data-elseif-odd]",
    );

    convertLoopConditions($simpleLoopElements);

    const $innerConditionalElements = $child
      .find(CONDITIONAL_ATTRIBUTES.join(", "))
      .filter(
        (_, el) =>
          !$(el).closest(
            '[data-wp="wp_loop"], [data-wp="wp_query"], [data-wp="post_terms"], [data-wp-terms], [data-acf-repeater], [data-acf-group], [data-acf-flexible], [data-acf-relation]',
          ).length,
      );

    const $innerElementsWithAcfAttr = $element
      .find(`${ACF_SELECTORS.join(", ")}`)
      .addBack(`${ACF_SELECTORS.join(", ")}`);

    insertPHP(
      $child,
      `\t\t<?php if ( get_row_layout() === '${layoutId}' ) : ?>\n`,
      { output: "before", outputPattern: null },
    );

    if ($innerConditionalElements.length > 0) {
      processPhpConditionGroup($innerConditionalElements);
    }

    if (incrementAttribute) {
      let incrementAttributeValue = $child.attr(incrementAttribute).trim();
      const separator = incrementAttributeValue.length > 0 ? " " : "";

      incrementAttributeValue += `${separator}${incrementPrefix}<?php the_row_index(); ?>`;

      $child.attr(incrementAttribute, incrementAttributeValue);
    }

    for (const el of $innerElementsWithAcfAttr.get()) {
      const $closestWpQueryElement = $(el).closest(
        '[data-wp="wp_query"], [data-wp="wp_loop"], [data-wp-terms], [data-acf-relation]',
      );

      if ($closestWpQueryElement.length === 0) {
        $(el).attr("data-acf-child", "true");
        continue;
      }

      if (el === $closestWpQueryElement.get(0)) {
        $(el).attr("data-acf-child", "true");
        continue;
      }

      if (isElementChildOf($closestWpQueryElement, $element)) {
        $(el).attr("data-acf-child", "false");
      } else {
        $(el).attr("data-acf-child", "true");
      }
    }

    insertPHP($child, "\t\t<?php endif; ?>\n", {
      output: "after",
      outputPattern: null,
    });

    $child.removeAttr("data-acf-layout");
  });

  insertPHP($element, "\t<?php endwhile; ?>\n", {
    output: "append",
    outputPattern: null,
  });
  insertPHP($element, "<?php endif; ?>\n", {
    output: "after",
    outputPattern: null,
  });

  $element.removeAttr("data-acf-flexible");
  $element.removeAttr("data-acf-context");
}

export function processAcfFlexibleLayoutFields() {
  const $repeaterElements = $("[data-acf-flexible]");

  $repeaterElements.each((_, el) => {
    processAcfFlexibleLayoutField($(el));
  });
}

export function processAcfFileFields() {
  const $fileElements = $("[data-acf-file]");

  $fileElements.each((_, el) => {
    const $el = $(el);
    const fieldKey = $el.data("acfFile");

    $el.attr("data-acf", fieldKey);
    $el.attr("data-output", "attribute");

    $el.removeAttr("data-acf-file");
  });
}

export function processAcfBgVideoFields() {
  const $bgVideoElements = $("[data-acf-bg-video]");

  $bgVideoElements.each((_, el) => {
    const $el = $(el);
    const fieldKey = $el.data("acfBgVideo");
    const $video = $el.find("video");
    const $sources = $el.find("source");

    $el.attr("data-acf-group", fieldKey);
    $el.attr("data-acf-file", "poster");
    $el.attr("data-output-attribute", "data-poster-url");

    $video.attr("data-acf-file", "poster");
    $video.attr("data-output-attribute", "style");
    $video.attr("data-output-pattern", "background-image: url('%s')");

    $sources.each((index, source) => {
      const $source = $(source);
      const fieldName = index === 0 ? "mp4" : "webm";
      $source.attr("data-acf-file", fieldName);
      $source.attr("data-output-attribute", "src");
      $source.attr("data-condition", "true");
    });

    $el.removeAttr("data-acf-bg-video");
    $el.removeAttr("data-video-urls");
  });
}

export function processAcfLightboxVideoFields() {
  const $videoElements = $("[data-acf-lightbox-video]");

  $videoElements.each((_, el) => {
    const $el = $(el);
    const fieldKey = $el.data("acfLightboxVideo");
    const isChild = $el.data("acfChild") || false;
    const context = $el.data("acfContext")
      ? formatAcfContextValue($el.data("acfContext"))
      : "false";
    const condition = $el.data("condition");
    const $scriptElement = $el.find("script.w-json");
    const jsonObject = JSON.parse($scriptElement.text());

    let beforePHP = isChild
      ? `<?php $${fieldKey} = get_sub_field( '${fieldKey}' ); ?>\n`
      : `<?php $${fieldKey} = get_field( '${fieldKey}', ${context} ); ?>\n`;

    insertPHP($el, beforePHP, { output: "before", outputPattern: null });

    if (condition) {
      insertPHP($el, `<?php if ( ! empty( $${fieldKey} ) ) : ?>\n`, {
        output: "before",
        outputPattern: null,
      });
      insertPHP($el, `<?php endif; ?>\n`, {
        output: "after",
        outputPattern: null,
      });
    }

    jsonObject.items[0].html = `<?php echo str_replace( '"', '\"', $${fieldKey} ); ?>`;

    delete jsonObject.items[0].url;
    delete jsonObject.items[0].originalUrl;
    delete jsonObject.items[0].thumbnailUrl;

    $scriptElement.text(JSON.stringify(jsonObject));

    $el.removeAttr("data-acf-lightbox-video");
    $el.removeAttr("data-acf-child");
    $el.removeAttr("data-acf-context");
  });
}

export function processAcfRelationFields() {
  const $relationElements = $("[data-acf-relation]");

  $relationElements.each((_, el) => {
    const $el = $(el);
    const fieldKey = $el.data("acfRelation");
    const fieldType = $el.data("acfRelationType");
    const $targetElement = $el.data("targetElement")
      ? $el.find($el.data("targetElement"))
      : $el;
    const context = $el.data("acfContext")
      ? formatAcfContextValue($el.data("acfContext"))
      : "false";
    const isChild = $el.data("acfChild") || false;

    let phpBefore = `<?php \n`;

    if (isChild) {
      phpBefore += `$${fieldKey} = get_sub_field( '${fieldKey}' ); \n`;
    } else {
      phpBefore += `$${fieldKey} = get_field( '${fieldKey}', ${context} ); \n`;
    }

    phpBefore += `if ( ! empty( $${fieldKey} ) ) : ?>`;

    insertPHP($targetElement, phpBefore, {
      output: "before",
      outputPattern: null,
    });

    if (fieldType === "wp_query") {
      $targetElement.attr("data-wp", "wp_query");
      $targetElement.attr("data-query-arg-post__in", `$${fieldKey}`);
    } else {
      const prependPhp = `<?php foreach ( $${fieldKey} as $related_post_index => $post ) :
			setup_postdata( $post ); ?>\n`;
      const appendPhp = `<?php endforeach; wp_reset_postdata(); ?>\n`;

      const $simpleLoopElements = $targetElement.find(
        "[data-if-first], [data-if-last], [data-if-even], [data-if-odd], [data-elseif-first], [data-elseif-last], [data-elseif-even], [data-elseif-odd]",
      );

      convertLoopConditions($simpleLoopElements);

      const hasChildrenWithConditions =
        $targetElement
          .children()
          .filter((_, child) => $(child).is(CONDITIONAL_ATTRIBUTES.join(", ")))
          .length > 0;

      if (!hasChildrenWithConditions) {
        $targetElement
          .children()
          .filter((index) => index > 0)
          .remove();
      } else {
        $targetElement
          .children()
          .filter((_, child) => !$(child).is(CONDITIONAL_ATTRIBUTES.join(", ")))
          .remove();

        processPhpConditionGroup($targetElement.children());
      }

      $targetElement.children().each((_, child) => {
        const $child = $(child);
        const anotherConditionalElements = $child.find(
          CONDITIONAL_ATTRIBUTES.join(", "),
        );

        if (anotherConditionalElements.length > 0) {
          processPhpConditionGroup(anotherConditionalElements);
        }
      });

      insertPHP($targetElement, prependPhp, {
        output: "prepend",
        outputPattern: null,
      });
      insertPHP($targetElement, appendPhp, {
        output: "append",
        outputPattern: null,
      });
    }

    insertPHP($targetElement, `<?php endif; ?>\n`, {
      output: "after",
      outputPattern: null,
    });

    $el.removeAttr("data-acf-relation");
    $el.removeAttr("data-acf-relation-type");
    $el.removeAttr("data-target-element");
    $el.removeAttr("data-acf-child");
    $el.removeAttr("data-acf-context");
  });
}

export function processAcfRepeaterField($element) {
  if (!$element) {
    return;
  }

  const fieldKey = $element.data("acfRepeater");
  const $targetElement = $element.data("targetElement")
    ? $element.find($element.data("targetElement"))
    : $element;
  const asGroup = $element.data("acfAsGroup") || false;
  const context = $element.data("acfContext")
    ? formatAcfContextValue($element.data("acfContext"))
    : "false";
  const incrementAttribute = $element.data("increment");
  const incrementPrefix = $element.data("incrementPrefix");
  const incrementAction = $element.data("incrementAction") || "replace";
  const phpBefore = `<?php if ( have_rows( '${fieldKey}', ${context} ) ) : ?>\n`;
  const phpPrepend = `<?php while ( have_rows( '${fieldKey}', ${context} ) ) : the_row(); ?>\n`;
  const phpAppend = `<?php endwhile; ?>\n`;
  const phpAfter = `<?php endif; ?>\n`;

  const $wrapper = asGroup ? $("<div></div>") : false;

  if ($wrapper) {
    $wrapper.append($element.contents());
    $element.empty();
    $element.append($wrapper);
  }

  insertPHP($element, phpBefore, { output: "before", outputPattern: null });

  const $simpleLoopElements = $element.find(
    "[data-if-first], [data-if-last], [data-if-even], [data-if-odd], [data-elseif-first], [data-elseif-last], [data-elseif-even], [data-elseif-odd]",
  );

  convertLoopConditions($simpleLoopElements);

  const hasChildrenWithConditions =
    $element
      .children()
      .filter((_, child) => $(child).is(CONDITIONAL_ATTRIBUTES.join(", ")))
      .length > 0;

  if (!hasChildrenWithConditions) {
    $element
      .children()
      .filter((index) => index > 0)
      .remove();
  } else {
    $element
      .children()
      .filter((_, child) => !$(child).is(CONDITIONAL_ATTRIBUTES.join(", ")))
      .remove();

    processPhpConditionGroup($element.children());
  }

  $element.children().each((_, child) => {
    const $child = $(child);
    const anotherConditionalElements = $child.find(
      CONDITIONAL_ATTRIBUTES.join(", "),
    );

    if (anotherConditionalElements.length > 0) {
      processPhpConditionGroup(anotherConditionalElements);
    }
  });

  const $innerElementsWithAcfAttr = $element
    .find(`${ACF_SELECTORS.join(", ")}`)
    .addBack(`${ACF_SELECTORS.join(", ")}`);

  for (const el of $innerElementsWithAcfAttr.get()) {
    const $closestWpQueryElement = $(el).closest(
      '[data-wp="wp_query"], [data-wp="wp_loop"], [data-wp-terms], [data-acf-relation]',
    );

    if ($closestWpQueryElement.length === 0) {
      $(el).attr("data-acf-child", "true");
      continue;
    }

    if (el === $closestWpQueryElement.get(0)) {
      $(el).attr("data-acf-child", "true");
      continue;
    }

    if (isElementChildOf($closestWpQueryElement, $element)) {
      $(el).attr("data-acf-child", "false");
    } else {
      $(el).attr("data-acf-child", "true");
    }
  }

  $element.children().each((_, child) => {
    const $child = $(child);

    if (incrementAttribute) {
      let incrementAttributeValue = $child.attr(incrementAttribute).trim();

      switch (incrementAction) {
        case "prepend":
          incrementAttributeValue = `${incrementPrefix}<?php the_row_index(); ?> ${incrementAttributeValue}`;
          break;
        case "append":
          incrementAttributeValue += ` ${incrementPrefix}<?php the_row_index(); ?>`;
          break;
        case "replace":
        default:
          incrementAttributeValue = `${incrementPrefix}<?php the_row_index(); ?>`;
          break;
      }

      insertPHP($child, incrementAttributeValue, {
        output: "attribute",
        outputAttribute: incrementAttribute,
        outputPattern: null,
      });
    }
  });

  insertPHP($targetElement, phpPrepend, {
    output: "prepend",
    outputPattern: null,
  });
  insertPHP($targetElement, phpAppend, {
    output: "append",
    outputPattern: null,
  });
  insertPHP($element, phpAfter, { output: "after", outputPattern: null });

  if ($wrapper) {
    $wrapper.before($wrapper.contents());
    $wrapper.remove();
  }

  $element.removeAttr("data-acf-repeater");
  $element.removeAttr("data-acf-as-group");
  $element.removeAttr("data-acf-context");
  $element.removeAttr("data-target-element");
  $element.removeAttr("data-increment");
  $element.removeAttr("data-increment-prefix");
  $element.removeAttr("data-increment-action");
}

export function processAcfRepeaterFields() {
  const $repeaterElements = $("[data-acf-repeater]");

  $repeaterElements.each((_, el) => {
    processAcfRepeaterField($(el));
  });
}
