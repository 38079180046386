import {
	processAcfBgImageFields,
	processAcfBgVideoFields,
	processAcfFields,
	processAcfFileFields,
	processAcfFlexibleLayoutFields,
	processAcfGroupFields,
	processAcfImageFields,
	processAcfLightboxVideoFields,
	processAcfLinkFields,
	processAcfRelationFields,
	processAcfRepeaterFields
} from './components/acf/acf';
import { processWpMenus } from './components/navigation';
import { preprocessTemplateParts, processEmbedElements, processGlobalConditions } from './components/php';
import { processNextPostElements, processPostContentFields, processPostDateFields, processPostExcerpts, processPostImages, processPostLinks, processPostTitles, processPreviousPostElements, processWpLoops, processWpQueries } from './components/post';
import { processSiteFields } from './components/site';
import { processPostTermsLoops, processTaxonomyElements, processTermLoops } from './components/taxonomies/taxonomies';
import './images/surface-bg.jpg';
import './styles/index.scss';
import { createConvertHtmlToPhpButton, createCopyPhpToClipboardButton, createDownloadButton } from './view';

const $convertButton = createConvertHtmlToPhpButton();

$convertButton.on('click', () => {
	console.log('Conversion started');

	new Promise((resolve) => {
		processSiteFields();
		processWpMenus();
		processGlobalConditions();
		processAcfImageFields();
		processAcfBgImageFields();
		processAcfBgVideoFields();
		processAcfFileFields();
		processAcfGroupFields();
		processAcfFlexibleLayoutFields();
		processAcfRepeaterFields();
		processAcfLinkFields();
		processAcfRelationFields();
		processAcfLightboxVideoFields();

		processTermLoops();
		processPostTermsLoops();
		processTaxonomyElements();

		processWpLoops();
		processWpQueries();
		processNextPostElements();
		processPreviousPostElements();
		processPostTitles();
		processPostExcerpts();
		processPostContentFields();
		processPostLinks();
		processPostImages();
		processPostDateFields();

		processAcfFields();
		preprocessTemplateParts();
		processEmbedElements();
		resolve();
	}).then(() => {
		$convertButton.remove();
		createDownloadButton();
		createCopyPhpToClipboardButton();
		console.log('Conversion complete');
	});
});

// import { processTaxonomyElements } from "./components/taxonomies/taxonomies";

// function convertAcfTextFields() {
//   const acfTextFields = $(`[data-acf-text]`);

//   acfTextFields.each((i, el) => {
//     const $el = $(el);
//     const fieldKey = $el.data("acfText");
//     const isChild = $el.closest("[data-acf-repeat]").length > 0;

//     if (isChild) {
//       $el.before(
//         document.createTextNode(
//           `<?php if ( ! empty( get_sub_field( '${fieldKey}' ) ) ) : ?>`,
//         ),
//       );
//       $el.html(
//         document.createTextNode(`<?php the_sub_field( '${fieldKey}' ); ?>`),
//       );
//     } else {
//       $el.before(
//         document.createTextNode(
//           `<?php if ( !empty( get_field('${fieldKey}' ) ) ) : ?>`,
//         ),
//       );
//       $el.html(document.createTextNode(`<?php the_field( '${fieldKey}' ); ?>`));
//     }

//     $el.after(document.createTextNode(`<?php endif; ?>`));
//   });
// }

// function convertAcfImageFields() {
//   const acfImageFields = $(`[data-acf-image]`);
//   acfImageFields.each((i, el) => {
//     const $el = $(el);
//     const fieldKey = $el.data("acfImage");
//     const isChild = $el.closest("[data-acf-repeat]").length > 0;

//     if (isChild) {
//       $el.before(
//         document.createTextNode(
//           `<?php if ( ! empty( get_sub_field( '${fieldKey}' ) ) ) : ?>`,
//         ),
//       );
//       $el.attr("src", `<?php the_sub_field( '${fieldKey}' ); ?>`);
//     } else {
//       $el.before(
//         document.createTextNode(
//           `<?php if ( ! empty( get_field( '${fieldKey}' ) ) ) : ?>`,
//         ),
//       );
//       $el.attr("src", `<?php the_field( '${fieldKey}' ); ?>`);
//     }
//     $el.after(document.createTextNode(`<?php endif; ?>`));
//   });
// }

// function convertAcfBackgroundImages() {
//   const acfBackgroundFields = $(`[data-acf-background]`);

//   acfBackgroundFields.each((i, el) => {
//     const $el = $(el);
//     const fieldKey = $el.data("acfBackground");
//     const isChild = $el.closest("[data-acf-repeat]").length > 0;

//     if (isChild) {
//       $el.css(
//         "background-image",
//         `url("<?php the_sub_field( '${fieldKey}' ); ?>")`,
//       );
//     } else {
//       // $el.css('color', 'red');
//       $el.css(
//         "background-image",
//         `url("<?php the_field( '${fieldKey}' ); ?>")`,
//       );
//     }
//   });
// }

// function convertAcfRepeaterFields() {
//   const acfRepeaterFields = $(`[data-acf-repeat]`);
//   acfRepeaterFields.each((i, el) => {
//     const $el = $(el);
//     const fieldKey = $el.data("acfRepeat");
//     const incrementAttribute = $el.data("increment");
//     const incrementPrefix = $el.data("incrementPrefix");

//     $el.before(
//       document.createTextNode(`<?php if ( have_rows( '${fieldKey}' ) ) : ?>`),
//     );

//     $el.children().each((i, child) => {
//       const $child = $(child);

//       if (i > 0) {
//         $child.remove();
//       }

//       $child.before(
//         document.createTextNode(
//           `<?php while ( have_rows( '${fieldKey}' ) ) : the_row(); ?>`,
//         ),
//       );

//       if (incrementAttribute) {
//         $child.attr(
//           incrementAttribute,
//           `${incrementPrefix}<?php the_row_index(); ?>`,
//         );
//       }

//       $child.after(document.createTextNode(`<?php endwhile; ?>`));
//     });

//     $el.after(document.createTextNode(`<?php endif; ?>`));
//   });
// }

// function convertWpPostTitle() {
//   const wpTitleFields = $(`[data-wp="post_title"]`);

//   wpTitleFields.each((_, el) => {
//     const $el = $(el);
//     $el.html(document.createTextNode(`<?php the_title(); ?>`));
//   });
// }

// function convertWpPostContent() {
//   const wpContentFields = $(`[data-wp="post_content"]`);

//   wpContentFields.each((i, el) => {
//     const $el = $(el);
//     $el.html(document.createTextNode(`<?php the_content(); ?>`));
//   });
// }

// function convertWpPostLinks() {
//   const $wpPostLinks = $(`[data-wp="post_link"]`);

//   $wpPostLinks.each((i, el) => {
//     const $el = $(el);
//     $el.attr("href", `<?php the_permalink(); ?>`);

//     $el.removeAttr("data-wp");
//   });
// }

// function convertWpPostThumbnails() {
//   const wpPostThumbnails = $(`[data-wp="post_image"]`);

//   wpPostThumbnails.each((i, el) => {
//     const $el = $(el);

//     $el.before(
//       document.createTextNode(`<?php if ( has_post_thumbnail() ) : ?>`),
//     );
//     $el.attr("src", `<?php the_post_thumbnail_url(); ?>`);
//     $el.after(document.createTextNode(`<?php endif; ?>`));
//   });
// }

// function convertWpPostBgThumbnails() {
//   const wpPostBgThumbnails = $(`[data-wp="post_bg_image"]`);

//   wpPostBgThumbnails.each((i, el) => {
//     const $el = $(el);
//     $el.css("background-image", `url("<?php the_post_thumbnail_url(); ?>")`);
//   });
// }

// function convertWpLoops() {
//   const $wpLoop = $(`[data-wp="post-loop"]`);
//   const incrementAttribute = $wpLoop.data("increment");
//   const incrementPrefix = $wpLoop.data("incrementPrefix");
//   const incrementValue = $wpLoop.data("incrementValue");
//   const $children = $wpLoop.children();

//   $wpLoop.each((i, el) => {
//     const $el = $(el);

//     let loopStartPHP = "";
//     let loopEndPHP = "";

//     if (incrementAttribute && !incrementValue) {
//       loopStartPHP = "<?php $counter = 0; ?>";
//       loopEndPHP = "<?php $counter++; ?>";
//     }

//     loopStartPHP += "<?php while ( have_posts() ) : the_post(); ?>";
//     loopEndPHP += "<?php endwhile; wp_reset_postdata(); ?>";

//     $el.prepend(document.createTextNode(loopStartPHP));
//     $el.append(document.createTextNode(loopEndPHP));

//     $children.each((i, child) => {
//       if (i > 0) {
//         $(child).remove();
//       }

//       if (incrementAttribute) {
//         $(child).attr(
//           incrementAttribute,
//           `${incrementPrefix}${
//             incrementValue ? incrementValue : "<?php echo $counter; ?>`"
//           }`,
//         );
//       }
//     });

//     $el.removeAttr("data-wp");
//     $el.removeAttr("data-increment");
//     $el.removeAttr("data-increment-prefix");
//     $el.removeAttr("data-increment-value");
//   });
// }

// function convertWpTermLoops() {
//   const $wpLoop = $(`[data-wp-terms]`);

//   $wpLoop.each((i, el) => {
//     const $el = $(el);
//     const taxonomyName = $el.data("wpTerms");
//     const $children = $el.children();
//     const beforeContainerPHP = `$${taxonomyName}_terms = get_terms( array( 'taxonomy' => '${taxonomyName}' ) );

// 		if ( ! empty( ${taxonomyName}_terms ) ) : ?>`;

//     $el.before(document.createTextNode(beforeContainerPHP));
//     $el.prepend(
//       document.createTextNode(
//         `<?php foreach ($${taxonomyName}_terms as $${taxonomyName}_term) : ?>`,
//       ),
//     );
//     $el.append(document.createTextNode(`<?php endforeach; ?>`));
//     $el.after(document.createTextNode(`<?php endif; ?>`));

//     $children.each((i, child) => {
//       if (i > 0) {
//         $(child).remove();
//       }
//     });

//     $children.attr("data-wp-taxonomy", `$${taxonomyName}_term`);
//   });
// }

// // ACF Fields
// convertAcfRepeaterFields();
// convertAcfTextFields();
// convertAcfImageFields();
// convertAcfBackgroundImages();

// // WP Fields
// convertWpLoops();
// convertWpPostLinks();
// convertWpPostTitle();
// convertWpPostContent();
// convertWpPostThumbnails();
// convertWpPostBgThumbnails();
// convertWpTermLoops();
// processTaxonomyElements($(`[data-wp-taxonomy]`));
