import $ from 'jquery';
import { insertPHP } from '../../core';

const MENU_ITEM_ACTIONS = {
	title: ($el) => $el.text(`<?php echo esc_html( $menu_item->title ); ?>`),
	link: ($el) => {
		$el.removeClass('w--current');
		$el.attr('href', `<?php echo esc_url( $menu_item->url ); ?>`);
	},
};

export function processWpMenus() {
	const $elementsWithWpMenu = $('[data-wp="menu"]');

	$elementsWithWpMenu.each((_, el) => {
		const $el = $(el);
		const menuName = $el.data('menuName');
		const phpBefore = `<?php $menu_items = wp_get_nav_menu_items( '${menuName}' ); \nif ( ! empty( $menu_items ) ) : ?>\n`;
		const phpPrepend = `<?php foreach ( $menu_items as $menu_item_index => $menu_item ) : ?>\n`;
		const phpAppend = `<?php endforeach; ?>\n`;
		const phpAfter = '<?php endif; ?>\n';

		insertPHP($el, phpBefore, { output: 'before' });
		insertPHP($el, phpPrepend, { output: 'prepend' });

		$el.children().slice(1).remove();

		const menuItem = $el.children().get(0);
		const $targetPhpElements = $(menuItem)
			.find('[data-menu-item]')
			.addBack('[data-menu-item]');

		$targetPhpElements.each((_, menuItemElement) => {
			const $menuItemElement = $(menuItemElement);
			const menuItemTags = $menuItemElement
				.data('menuItem')
				.split(',')
				.map((tag) => tag.trim());

			menuItemTags.forEach((tag) => MENU_ITEM_ACTIONS[tag]($menuItemElement));

			$menuItemElement.removeAttr('data-menu-item');
		});

		insertPHP($el, phpAppend, { output: 'append' });
		insertPHP($el, phpAfter, { output: 'after' });

		$el.removeAttr('data-menu-name');
		$el.removeAttr('data-wp');
	});
}
