import $ from 'jquery';
import {
	getFileProtectionPhpString,
	getPhpCommentForPageTemplate,
} from '../utils';
export function insertPHP($element, php, insertOptions = {}) {
	if (!$element) {
		console.error('Element not found:', $element);
		return;
	}

	const $el = $element instanceof $ ? $element : $($element);
	const options = {
		output: $el.data('output') ?? 'html',
		outputAttribute: $el.data('outputAttribute') ?? null,
		outputPattern: $el.data('outputPattern') ?? null,
		...insertOptions,
	};
	const result = options.outputPattern
		? options.outputPattern.replace('%s', php)
		: php;

	switch (options.output) {
		case 'style':
			const style = $el.attr('style') ?? '';
			const addSemicolon = !style.endsWith(';');
			$el.attr('style', style + (addSemicolon ? ';' : '') + ' ' + result);
			break;
		case 'attribute':
			$el.attr(options.outputAttribute, result);
			break;
		case 'before':
			$el.before(document.createTextNode(result));
			break;
		case 'after':
			$el.after(document.createTextNode(result));
			break;
		case 'prepend':
			$el.prepend(document.createTextNode(result));
			break;
		case 'append':
			$el.append(document.createTextNode(result));
			break;
		case 'replace':
			$el.replaceWith(document.createTextNode(result));
			break;
		default:
			$el.html(document.createTextNode(result));
			break;
	}
}

export function preparePhpFile(content) {
	const file = new Blob([content], {
		type: 'text/php',
	});

	return file;
}

const decodeHtmlEntities = (html) => {
	return html.replace(/&([^;]+);/g, (match, entity) => {
		const entities = {
			amp: '&',
			lt: '<',
			gt: '>',
			quot: '"',
			'#39': "'",
			nbsp: ' ',
		};
		return entities[entity] || match;
	});
};

export function preparePhpTemplate(templateName = document.title) {
	const phpComment = getPhpCommentForPageTemplate(templateName);
	const phpProtectionString = getFileProtectionPhpString();

	return preparePhpFile(
		phpComment +
			phpProtectionString +
			decodeHtmlEntities($('html').prop('outerHTML'))
	);
}
